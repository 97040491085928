import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const NavLink = styled(Link)`
  color: #222;
  font-weight: ${props => props.fontWeight || "normal"};
  line-height: 2;
  margin: 0 0.5rem 0 0;
  padding: 0.5rem;
  text-decoration: none;
  &.current-page {
    border-bottom: 2px solid #222;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

const Header = () => (
  <header
    css={css`
      background: #eee;
      border-bottom: 1px solid #ddd;
      justify-content: space-between;
      padding: 25px;
    `}
  >
    <NavLink
      to="/"
      fontWeight="bold"
      css={css`
        display: block;
      `}
    >
      <h1
        css={css`
          padding-bottom: 16px;
        `}
      >
        Amy M Haddad
      </h1>
    </NavLink>
    <nav
      css={css`
        margin-top: 0;
        display: block;

        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          align-content: center;
        }
      `}
    >
      <NavLink
        to="/"
        activeClassName="current-page"
        css={css`
          @media (max-width: 600px) {
            width: 110px;
          }
        `}
      >
        Home
      </NavLink>
      <NavLink
        to="/about/"
        activeClassName="current-page"
        css={css`
          @media (max-width: 600px) {
            width: 110px;
          }
        `}
      >
        About
      </NavLink>
      <NavLink
        to="/contact/"
        activeClassName="current-page"
        css={css`
          @media (max-width: 600px) {
            width: 110px;
          }
        `}
      >
        Contact
      </NavLink>

      <NavLink
        to="/press-clips/"
        activeClassName="current-page"
        css={css`
          @media (max-width: 600px) {
            width: 110px;
          }
        `}
      >
        Press Clips
      </NavLink>

      <NavLink
        to="/newsletter/"
        activeClassName="current-page"
        css={css`
          @media (max-width: 600px) {
            width: 110px;
          }
        `}
      >
        Newsletter
      </NavLink>
      

      <NavLink
        to="https://a.co/d/1MLhFyX"
        activeClassName="current-page"
        css={css`
          @media (max-width: 600px) {
            width: 110px;
          }
        `}
      >
        Book
      </NavLink>


  
    </nav>
  </header>
)

export default Header
