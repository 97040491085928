import React from "react"
import { Global, css } from "@emotion/core"
import Helmet from "react-helmet"
import Header from "./header"
import useSiteMetadata from "../hooks/use-sitemetadata"

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()

  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }
          /* More info: https://bit.ly/2PsCnzk */
          * + * {
            margin-top: 1rem;
          }
          html,
          body {
            margin: 5px;
            min-width: 300px;
            color: #555;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif, "Apple Color Emoji",
              "Segoe UI Emoji", "Segoe UI Symbol";
            font-size: 21px;
            line-height: 1.4;
            /* remove margin for the main div that Gatsby mounts into */
            > div {
              margin-top: 0;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #222;
            line-height: 1.1;
            + * {
              margin-top: 0.5rem;
            }
          }
          strong {
            color: #222;
          }
          li {
            margin-top: 1.1rem;
          }
        `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />
      <main
        css={css`
          display: flex;
          justify-content: center;

          @media (max-width: 600px) {
            flex-direction: column;
          }
          flex-direction: row-reverse;

          @media (max-width: 600px) {
            margin: 10px 10px 20px;
          }

          margin: 10px 150px 20px;
        `}
      >
        {children}
      </main>
    </>
  )
}

export default Layout
